import { FormControl, FormLabel, Input, Button, Switch, useToast, CircularProgress, Flex, Select } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { createOrganizer, listActivesStatesAndCities, } from "../../../../../services/api";
import { isValidCPF } from "../../../../../util/functions";
import { ICreateCompany } from "../../../../../interfaces/company-interface";
import { IOrganizerDto } from "../../../../../interfaces/organizer-interface";
import { IStates } from "../../../../../interfaces/states-interface";

interface IOrganizerFormProps {
    companyId: number;
    setOrganizerIdGlobalForm: (id: number) => void
    setOrganizerGlobalForm: (company: IOrganizerDto) => void
    company: ICreateCompany;
    nextTab: (index: number) => void;
    backTab: (index: number) => void;
}

export const OrganizerForm = ({ company, setOrganizerGlobalForm, setOrganizerIdGlobalForm, backTab, nextTab, companyId }: IOrganizerFormProps) => {
    const [organizer, setOrganizer] = useState<IOrganizerDto>({} as IOrganizerDto);
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [document_number, setDocumentNumber] = useState<string>("");
    const [birthdate, setBirthdate] = useState<Date>();
    const [birthdateString, setBirthdateString] = useState<string>("");
    const [professional_occupation, setProfessionalOccupation] = useState<string>("");
    const [ddd, setDdd] = useState<string>("");
    const [phone_number, setPhoneNumber] = useState<string>("");
    const [phone_type, setPhoneType] = useState<string>("");
    const [street, setStreet] = useState<string>("");
    const [neighborhood, setNeighborhood] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [number, setNumber] = useState<string>("");
    const [zip, setZip] = useState<string>("");
    const [complementary, setComplementary] = useState<string>("");
    const [reference_point, setReferencePoint] = useState<string>("");
    const [enderecoIsUtilizedCompany, setEnderecoIsUtilizedCompany] = useState<boolean>(false);
    const [organizerIsCreated, setOrganizerIsCreated] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [monthly_income, setMonthlyIncome] = useState<number>(0);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('12345678');

    const [allStatesAndCitiesActives, setAllStatesAndCitiesActives] = useState<IStates[]>([])
    const [states, setStates] = useState<{id: number, name: string}[]>([])
    const [cities, setCities] = useState<{id: number, name: string}[]>([])

    const toast = useToast();

    async function getStatesAndCitiesActives(){
        listActivesStatesAndCities()
        .then(({data}) => {
            const allStates = data.data as IStates[]
            setAllStatesAndCitiesActives(allStates)

            const statesArray = allStates.map(state => {
                return {
                    id: state.id,
                    name: state.name
                }
            })
            setStates(statesArray) 
        })
        .catch(error => {
            toast({
                title: "Erro",
                description: "Ocorreu um erro ao listar os estados e cidades ativas",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        })
    }

    const handleSelectState = (name: string) => {
        if(!name){
            setState("")
            setCities([])
        }else{
            setState(name)
        }

        if(name){
            const state = allStatesAndCitiesActives.find(state => state.name === name)
            const allCities = state?.cities
            const citiesArray = allCities?.map(city => {
                return {
                    id: city.id,
                    name: city.name
                }
            })
            setCities(citiesArray ?? [])
        }

        if(name && name !== state){
            setCity("")
        }
    }


    const handleSaveOrganizer = () => {
        setOrganizer({
            organizer_dto: {
                name: name,
                email: email,
                document_number: document_number,
                mother_name: '',
                birthdate: birthdate ? birthdateString : null,
                professional_occupation: professional_occupation,
                ddd: ddd,
                phone_number: phone_number,
                phone_type: 'mobile',
                password: password,
                company_id: companyId,
                monthly_income: Number(monthly_income)
            },
            address_dto: !enderecoIsUtilizedCompany ? {
                street: street,
                neighborhood: neighborhood,
                city: city,
                state: state,
                number: number,
                zip: zip,
                latitude: '-27.590824',
                longitude: "-48.551262",
                complementary: complementary,
                reference_point: reference_point
            } : {
                street: company.address_dto.street,
                neighborhood: company.address_dto.neighborhood,
                city: company.address_dto.city,
                state: company.address_dto.state,
                number: company.address_dto.number,
                zip: company.address_dto.zip,
                latitude: company.address_dto.latitude,
                longitude: company.address_dto.longitude,
                complementary: company.address_dto.complementary,
                reference_point: company.address_dto.reference_point
            }
        })
        console.log(organizer);
    }

    const handleChangeCpf = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setDocumentNumber(value);
        setIsValid(isValidCPF(value));
    };

    function isValidEmail(email: string) {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    }

    const handleCreateOrganizer = () => {
        const documentIsValid = isValidCPF(document_number);

        if (state.length === 0) {
            toast({
                title: "Estado inválido",
                description: "Por favor, selecione um estado",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (city.length === 0){
            toast({
                title: "Cidade inválida",
                description: "Por favor, selecione uma cidade",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if(name.length === 0){
            toast({
                title: "Nome inválido",
                description: "Por favor, insira um nome válido!.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return
        }

        if(email.length === 0 || !isValidEmail(email)){
            toast({
                title: "Email inválido",
                description: "Por favor, insira um Email válido!.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return
        }

        if(password.length < 6){
            toast({
                title: "Senha inválida",
                description: "A senha deve ter pelo menos 6 caracteres.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return
        }

        if (!documentIsValid) {
            toast({
                title: "CPF inválido",
                description: "Por favor, insira um CPF válido.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        setIsLoading(true);
        const data = {
            organizer_dto: {
                name: name,
                email: email,
                document_number: document_number,
                mother_name: '',
                birthdate: birthdate ? birthdateString : null,
                professional_occupation: professional_occupation,
                ddd: ddd,
                phone_number: phone_number,
                phone_type: 'mobile',
                password: password,
                monthly_income: Number(monthly_income),
                company_id: companyId
            },
            address_dto: enderecoIsUtilizedCompany ? {
                street: company.address_dto.street,
                neighborhood: company.address_dto.neighborhood,
                city: company.address_dto.city,
                state: company.address_dto.state,
                number: company.address_dto.number,
                zip: company.address_dto.zip,
                latitude: company.address_dto.latitude,
                longitude: company.address_dto.longitude,
                complementary: company.address_dto.complementary,
                reference_point: company.address_dto.reference_point
            } : {
                street: street,
                neighborhood: neighborhood,
                city: city,
                state: state,
                number: number,
                zip: zip,
                latitude: '-27.590824',
                longitude: "-48.551262",
                complementary: complementary,
                reference_point: reference_point
            }
        }
        createOrganizer(data).then((response) => {
            setOrganizerGlobalForm(data);
            setOrganizerIdGlobalForm(response.data.data.id);
            toast({
                title: "Organizador cadastrado com sucesso",
                status: "success",
                duration: 9000,
                isClosable: true,
            });
            setOrganizerIsCreated(true);
            setIsLoading(false);
            nextTab(2)
        }).catch((error) => {
            toast({
                title: "Erro ao cadastrar organizador",
                status: "error",
                description: error.response.data.message,
                duration: 9000,
                isClosable: true,
            });
            setIsLoading(false);
        });
    }

    const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        handleSaveOrganizer();
        handleCreateOrganizer();
    }

    useEffect(() => {
        getStatesAndCitiesActives()
    }, [])

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <h1>
                Dados Pessoais
            </h1>
            <form
                style={{
                    borderTop: "1px solid #ccc",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "start",
                            padding: "10px",
                            width: "100%",
                        }}
                    >

                        <div
                            style={{
                                width: "50%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                alignItems: "start",
                                padding: "10px",
                            }}
                        >
                            <FormControl className="itemForm">
                                <FormLabel>
                                    Nome
                                </FormLabel>
                                <Input type="text" placeholder="Nome" onChange={(e) => setName(e.target.value)} />
                            </FormControl>

                            <FormControl className="itemForm">
                                <FormLabel>
                                    Email
                                </FormLabel>
                                <Input type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                            </FormControl>
                            {
                                <>
                                    <FormControl className="itemForm">
                                        <FormLabel>
                                            CPF
                                        </FormLabel>
                                        <Input value={document_number} isInvalid={document_number.length >= 11 && !isValid} _valid={{
                                            colorScheme: document_number.length >= 11 ? isValid ? "green" : "red.500" : "black",
                                        }} minLength={11} maxLength={11} required type="number" placeholder="CPF..." onChange={(e) => handleChangeCpf(e)} />
                                        {
                                            document_number.length >= 11 ? isValid ? (
                                                <span style={{ color: 'green' }}></span>
                                            ) : (
                                                <span style={{ color: 'red' }}>CPF inválido</span>
                                            )
                                                : null
                                        }
                                    </FormControl>

                                    <FormControl className="itemForm">
                                        <FormLabel>
                                            Data de Nascimento
                                        </FormLabel>
                                        <Input type="date" placeholder="Data de Nascimento" onChange={(e) => {
                                            let dayNumber: number = new Date(e.target.value).getDate() + 1;
                                            let monthNumber: number = new Date(e.target.value).getMonth() + 1;
                                            let yearNumber: number = new Date(e.target.value).getFullYear();
                                            let dayString: string = dayNumber.toString();
                                            let monthString: string = monthNumber.toString();
                                            let yearString: string = yearNumber.toString();
                                            if (dayNumber < 10) {
                                                dayString = `0${dayNumber}`;
                                            }
                                            if (monthNumber < 10) {
                                                monthString = `0${monthNumber}`;
                                            }
                                            let fullDate: string = `${dayString}/${monthString}/${yearString}`;

                                            setBirthdateString(fullDate);
                                            console.log(fullDate)
                                            setBirthdate(new Date(e.target.value))
                                        }} />
                                    </FormControl>
                                    <FormControl className="itemForm">
                                        <FormLabel >
                                            Profissão
                                        </FormLabel>
                                        <Input type="text" placeholder="Profissão" onChange={(e) => setProfessionalOccupation(e.target.value)} />
                                    </FormControl>
                                </>
                            }
                            <FormControl className="itemForm">
                                <FormLabel>
                                    DDD
                                </FormLabel>
                                <Input type="text" maxLength={2} minLength={2} placeholder="DDD" onChange={(e) => setDdd(e.target.value)} />
                            </FormControl>

                            <FormControl className="itemForm" >
                                <FormLabel>
                                    Telefone
                                </FormLabel>
                                <Input type="text" maxLength={9} minLength={9} placeholder="Telefone" onChange={(e) => setPhoneNumber(e.target.value)} />
                            </FormControl >

                            <FormControl className="itemForm">
                                <FormLabel>
                                    Renda Mensal
                                </FormLabel>
                                <Input type="number" maxLength={12} placeholder="Renda Mensal" onChange={(e) => setMonthlyIncome(Number(e.target.value))} />
                            </FormControl>
                            
                            <FormControl className="itemForm">
                                <FormLabel>
                                    Senha
                                </FormLabel>
                                <Input type="password" placeholder="Senha" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </FormControl>

                        </div>

                        <div style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-around",
                            alignItems: "center",
                            padding: "10px",
                        }}>
                            <h1 style={{
                                borderBottom: "1px solid #ccc",
                            }} >
                                Endereço
                            </h1>
                            <FormControl className="itemForm">
                                <FormLabel>Utilizar o mesmo endereço da empresa?</FormLabel>
                                <Switch
                                    onChange={() => {
                                        console.log(company)
                                        setEnderecoIsUtilizedCompany(!enderecoIsUtilizedCompany)
                                    }}
                                    colorScheme="green"
                                />
                            </FormControl>
                            {
                                !enderecoIsUtilizedCompany && <>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-around",
                                            width: "100%",
                                        }}
                                    >
                                        <FormControl className="itemForm">
                                            <FormLabel>
                                                Rua
                                            </FormLabel>
                                            <Input type="text" placeholder="Rua" onChange={(e) => setStreet(e.target.value)} />
                                        </FormControl>

                                        <FormControl className="itemForm">
                                            <FormLabel>
                                                Bairro
                                            </FormLabel>
                                            <Input type="text" placeholder="Bairro" onChange={(e) => setNeighborhood(e.target.value)} />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel>Estado</FormLabel>
                                            <Select
                                                className="input"
                                                name="estado"
                                                placeholder="Selecione um estado"
                                                onChange={(e) => handleSelectState(e.target.value)}
                                                errorBorderColor="pink.400"
                                            >
                                                {states.map((state) => (
                                                            <option key={state.id} value={state.name}>
                                                                {state.name}
                                                            </option>
                                                        )
                                                    )
                                                }
                                            </Select>
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel>Cidade</FormLabel>
                                            <Select
                                                className="input"
                                                name="cidade"
                                                placeholder="Selecione uma cidade"
                                                onChange={(e) => setCity(e.target.value)}
                                                errorBorderColor="pink.400"
                                            >
                                                {cities.map((city) => (
                                                            <option key={city.id} value={city.name}>
                                                                {city.name}
                                                            </option>
                                                        )
                                                    )
                                                }
                                            </Select>
                                        </FormControl>

                                        <FormControl className="itemForm">
                                            <FormLabel>
                                                Número
                                            </FormLabel>
                                            <Input type="text" placeholder="Número" onChange={(e) => setNumber(e.target.value)} />
                                        </FormControl>

                                        <FormControl className="itemForm">
                                            <FormLabel>
                                                CEP
                                            </FormLabel>
                                            <Input type="text" placeholder="CEP" onChange={(e) => setZip(e.target.value)} />
                                        </FormControl>

                                        <FormControl className="itemForm">
                                            <FormLabel>
                                                Complemento
                                            </FormLabel>
                                            <Input type="text" placeholder="Complemento" onChange={(e) => setComplementary(e.target.value)} />
                                        </FormControl>

                                        <FormControl className="itemForm">
                                            <FormLabel>
                                                Ponto de Referência
                                            </FormLabel>
                                            <Input type="text" placeholder="Ponto de Referência" onChange={(e) => setReferencePoint(e.target.value)} />
                                        </FormControl>
                                    </div>
                                </>
                            }

                        </div>
                    </div>

                </div>

                {
                    !organizerIsCreated && <div>
                        <Button
                            colorScheme="green"
                            width={"100%"}
                            height={"50px"}
                            type="button"
                            onClick={(e) => handleSubmit(e)}
                        >
                            {
                                isLoading ? <CircularProgress
                                    isIndeterminate
                                    color="blue"
                                    size="24px"
                                /> : 'Cadastrar'
                            }
                        </Button>
                    </div>

                }
            </form >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    alignItems: "center",
                    borderTop: "1px solid #ccc",
                    padding: "10px",
                    marginTop: "20px",
                    width: "100%",
                }}
            >
                

            </div>
        </div >
    );
}