import { AddIcon } from "@chakra-ui/icons";
import { Grid, Flex, Button, Text, GridItem } from "@chakra-ui/react";
import Sidebar from "../../components/Sidebar";
import CompanyTable from "./CompanyTable";
import { useNavigate } from "react-router-dom";
import { Header } from "../../components/Header";


const CompanyPage: React.FC = () => {
    const navigate = useNavigate();
    function handleNavigate(path: string) {
        navigate(path)
    }


    return (
        <>
            <Grid
                templateColumns="auto 1fr"
                templateRows="auto 1fr"
                minH="100vh"
                p={0}
                gap={0}
            >
                <GridItem
                    as="aside" 
                    colSpan={1} 
                    rowSpan={2} 
                    bg="gray.100" 
                    h="100vh"
                    position="sticky"
                    top="0"
                >
                    <Sidebar />
                </GridItem>

                <GridItem as="header" colStart={2} colSpan={1} bg="gray.200" w="100%">
                    <Header />
                </GridItem>

                <GridItem colStart={2} rowStart={2} w="100%" h="100%">
                    <Flex>
                        <div className="content">
                            <Flex style={{marginTop: '-5%'}} justify="space-between" align="center" mb={4}>
                                <Text fontSize="4xl" >Empresas</Text>
                                <Button
                                    leftIcon={<AddIcon />}
                                    colorScheme="green"
                                    onClick={() => handleNavigate('/organizer/form')}
                                >
                                    Nova Empresa
                                </Button>
                            </Flex>
                            <CompanyTable />
                        </div>
                    </Flex>
                </GridItem>
            </Grid>
        </>
    );
}

export default CompanyPage;