import { useEffect, useState } from "react"
import { Button, CircularProgress, Flex, FormControl, FormLabel, Input, Select, Switch, useToast } from "@chakra-ui/react"
import { IResponseCreateContaBancaria, IContaBancariaCreate, IBank } from "../../../../../interfaces/banckaccount-interface";
import { ICreateCompany } from "../../../../../interfaces/company-interface";
import { listBanks, createBankAccount } from "../../../../../services/api";

interface IContaBancariaFormProps {
    company_id: number;
    company: ICreateCompany;
    nextTab: (index: number) => void;
    backTab: (index: number) => void;
    setContaBancariaGlobal: (bankAccount: IResponseCreateContaBancaria) => void
    setContaBancariaIdGlobal: (bankAccountId: string) => void
}

export const ContaBancariaForm = ({ backTab, company_id, nextTab, setContaBancariaGlobal, company, setContaBancariaIdGlobal }: IContaBancariaFormProps) => {
    const [contaBancaria, setContaBancaria] = useState<IContaBancariaCreate>({} as IContaBancariaCreate)
    const [holderName, setHolderName] = useState<string>('')
    const [bankCode, setBankCode] = useState<string>('')
    const [branchNumber, setBranchNumber] = useState<string>('')
    const [branchCheckDigit, setBranchCheckDigit] = useState<string>('')
    const [accountNumber, setAccountNumber] = useState<string>('')
    const [accountCheckDigit, setAccountCheckDigit] = useState<string>('')
    const [accountType, setAccountType] = useState<string>('checking')
    const [transferEnabled, setTransferEnabled] = useState<boolean>(true)
    const [transferInterval, setTransferInterval] = useState<string>('daily')
    const [transferDay, setTransferDay] = useState<string>('')
    const [bankName, setBankName] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [bankAccountIsCreated, setBankAccountIsCreated] = useState<boolean>(false)
    const [banks, setBanks] = useState<IBank[]>([])

    const handleGetBanks = () => {
        listBanks().then((response) => {
            if (response.data.data !== undefined) {
                setBanks(response.data.data);
            }
        }).catch((error) => {
            console.log(error)
        })
    }
    const toast = useToast();

    useEffect(() => {
        handleGetBanks()
    }, []);

    const handleSetBank = (value: string) => {
        const bank = banks.find((bank) => bank.long_name === value)
        setBankName(value)
        if (bank) {
            if (bank?.code) {
                setBankCode(bank.code.toString())
            } else {
                toast({
                    title: "Código do banco não encontrado",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                })

            }
        }
    }


    const handleSaveBankAccount = () => {
        setContaBancaria({
            holder_name: holderName,
            holder_type: company.company_dto.type === "company" ? "company" : "individual",
            holder_document: company.company_dto.document_number!,
            bank_code: bankCode,
            branch_number: branchNumber,
            branch_check_digit: branchCheckDigit,
            account_number: accountNumber,
            account_check_digit: accountCheckDigit,
            account_type: accountType,
            transfer_enabled: transferEnabled,
            transfer_interval: transferInterval,
            transfer_day: Number(transferDay),
            company_id: company_id,
        });
    }

    const handleSetInterval = (value: string) => {
        setTransferInterval(value)
        if (value === "weekly") {
            setTransferDay("1")
        }
    }

    const handleCreateBankAccount = async () => {
        setIsLoading(true)
        if (Number(transferDay) > 31) {
            toast({
                title: "Dia de transferência inválido",
                status: "error",
                duration: 9000,
                isClosable: true,
            })
            setIsLoading(false)
            return
        }
        const data = {
            holder_name: holderName,
            holder_type: company.company_dto.type === "corporation" ? "company" : "individual",
            holder_document: company.company_dto.document_number!,
            bank_code: bankCode,
            branch_number: branchNumber,
            branch_check_digit: branchCheckDigit,
            account_number: accountNumber,
            account_check_digit: accountCheckDigit,
            account_type: accountType,
            transfer_enabled: transferEnabled,
            transfer_interval: transferInterval,
            transfer_day: Number(transferDay),
            company_id: company_id,
        }
        createBankAccount(data).then((response) => {
            if (response.status === 201) {
                setContaBancariaGlobal(response.data)
                setContaBancariaIdGlobal(response.data.data.id)
                toast({
                    title: "Conta bancária cadastrada com sucesso",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                })
                nextTab(3)
                setBankAccountIsCreated(true)
            }
            setIsLoading(false)
        })
            .catch((error) => {
                setIsLoading(false)
                toast({
                    title: "Erro ao cadastrar conta bancária",
                    status: "error",
                    description: error.response.data.message,
                    duration: 9000,
                    isClosable: true,
                })
            });
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <h1>
                Conta Bancaria do Recebedor
            </h1>
            <form
                style={{
                    borderTop: "1px solid #ccc",
                }}
                onSubmit={(e) => {
                    e.preventDefault()
                    handleSaveBankAccount()
                    handleCreateBankAccount()
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        width: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "start",
                            padding: "10px",
                            width: "100%",
                        }}
                    >
                        <div
                            style={{
                                width: "50%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                alignItems: "start",
                                padding: "10px",
                            }}
                        >
                            <FormControl>
                                <FormLabel>Nome do titular</FormLabel>
                                <Input required value={holderName} onChange={(e) => setHolderName(e.target.value)} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Nome do banco</FormLabel>
                                <Input
                                    required
                                    value={bankName}
                                    onChange={(e) => handleSetBank(e.target.value)}
                                    list="banks"
                                />
                                <datalist id="banks">
                                    {banks.map((bank) => (
                                        <option key={bank.code} value={bank.long_name} />
                                    ))}
                                </datalist>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Número da agência</FormLabel>
                                <Input required value={branchNumber} type="number" onChange={(e) => setBranchNumber(e.target.value)} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Dígito da agência</FormLabel>
                                <Input required value={branchCheckDigit} type="number" onChange={(e) => setBranchCheckDigit(e.target.value)} />
                            </FormControl>
                        </div>

                        <div
                            style={{
                                width: "50%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                alignItems: "start",
                                padding: "10px",
                            }}
                        >
                            <FormControl>
                                <FormLabel>Número da conta</FormLabel>
                                <Input required value={accountNumber} type="number" onChange={(e) => setAccountNumber(e.target.value)} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Dígito da conta</FormLabel>
                                <Input required value={accountCheckDigit} type="number" onChange={(e) => setAccountCheckDigit(e.target.value)} />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Tipo de conta</FormLabel>
                                <Select required value={accountType} onChange={(e) => setAccountType(e.target.value)}>
                                    <option value="checking">Corrente</option>
                                    <option value="savings">Poupança</option>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Transferência habilitada</FormLabel>
                                <Switch
                                    isChecked={transferEnabled}
                                    onChange={(e) => setTransferEnabled(e.target.checked)}
                                    colorScheme="green"
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Intervalo de transferência</FormLabel>
                                <Select required value={transferInterval} onChange={(e) => handleSetInterval(e.target.value)}>
                                    <option value="daily">Diário</option>
                                    <option value="weekly">Semanal</option>
                                    <option value="monthly">Mensal</option>
                                </Select>
                            </FormControl>
                            {
                                transferInterval === "monthly" && <FormControl>
                                    <FormLabel>Dia de transferência</FormLabel>
                                    <Input required value={transferDay} type="number" maxLength={2} minLength={1} onChange={(e) => setTransferDay(e.target.value)} />
                                </FormControl>
                            }

                            {
                                transferInterval === "weekly" && <FormControl>	<FormLabel>Dia de transferência</FormLabel>
                                    <Select required value={transferDay} onChange={(e) => setTransferDay(e.target.value)}>
                                        <option value="1">Segunda-feira</option>
                                        <option value="2">Terça-feira</option>
                                        <option value="3">Quarta-feira</option>
                                        <option value="4">Quinta-feira</option>
                                        <option value="5">Sexta-feira</option>
                                    </Select>
                                </FormControl>
                            }
                        </div>
                    </div>
                    {!bankAccountIsCreated && <div
                        style={{ padding: "20px", }}
                    >
                        <Button
                            colorScheme="green"
                            width={"100%"}
                            height={"50px"}
                            type="submit"
                            isDisabled={isLoading}
                        >
                            {isLoading ? <CircularProgress
                                isIndeterminate
                                color="blue"
                                size="24px"
                            /> : 'Cadastrar'}
                        </Button>
                    </div>}
                </div>
            </form>
           
        </div >
    )
}