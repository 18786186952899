import { FormControl, FormLabel, Input, Button, useToast, CircularProgress, Flex, Grid, Select } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { isValidCPF } from "../../../../../util/functions";
import { IOrganizerUpdateDto, IResponseOrganizer } from "../../../../../interfaces/organizer-interface";
import Sidebar from "../../../../../components/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { getOrganizerById, listActivesStatesAndCities, updateOrganizer } from "../../../../../services/api";
import { IStates } from "../../../../../interfaces/states-interface";



export const OrganizerFormEdit = () => {
    const [organizer, setOrganizer] = useState<IResponseOrganizer>({} as IResponseOrganizer);
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [document_number, setDocumentNumber] = useState<string>("");
    const [birthdate, setBirthdate] = useState<string>("");
    const [birthdateString, setBirthdateString] = useState<string>("");
    const [professional_occupation, setProfessionalOccupation] = useState<string>("");
    const [ddd, setDdd] = useState<string>("");
    const [phone_number, setPhoneNumber] = useState<string>("");
    const [street, setStreet] = useState<string>("");
    const [neighborhood, setNeighborhood] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [number, setNumber] = useState<string>("");
    const [zip, setZip] = useState<string>("");
    const [complementary, setComplementary] = useState<string>("");
    const [reference_point, setReferencePoint] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [monthly_income, setMonthlyIncome] = useState<number>(0);
    const [isValid, setIsValid] = useState<boolean>(true);

    const [allStatesAndCitiesActives, setAllStatesAndCitiesActives] = useState<IStates[]>([])
    const [states, setStates] = useState<{id: number, name: string}[]>([])
    const [cities, setCities] = useState<{id: number, name: string}[]>([])

    let initialState = ''

    async function getStatesAndCitiesActives(){
        listActivesStatesAndCities()
        .then(({data}) => {
            const allStates = data.data as IStates[]
            setAllStatesAndCitiesActives(allStates)

            const statesArray = allStates.map(state => {
                return {
                    id: state.id,
                    name: state.name
                }
            })
            setStates(statesArray)

            const state = allStates.find(state => state.name === initialState)
            const allCities = state?.cities
            const citiesArray = allCities?.map(city => {
                return {
                    id: city.id,
                    name: city.name
                }
            })
            setCities(citiesArray ?? [])
        })
        .catch(error => {
            toast({
                title: "Erro",
                description: "Ocorreu um erro ao listar os estados e cidades ativas",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        })
    }

    const handleSelectState = (name: string) => {
        if(!name){
            setState("")
            setCities([])
        }else{
            setState(name)
        }

        if(name){
            const state = allStatesAndCitiesActives.find(state => state.name === name)
            const allCities = state?.cities
            const citiesArray = allCities?.map(city => {
                return {
                    id: city.id,
                    name: city.name
                }
            })
            setCities(citiesArray ?? [])
        }

        if(name && name !== state){
            setCity("")
        }


    }

    async function handleInit(){
        await init();
        getStatesAndCitiesActives()
    }

    useEffect(() => {
        handleInit()
    }, []);

    const convertDateToISO = (dateString: string) => {
        const [day, month, year] = dateString.split('/');
        return `${year}-${month}-${day}`;
    };


    const init = async () => {
        const response = await getOrganizerById(Number(id));
        setOrganizer(response.data);
        const { data } = response.data;
        setName(data.name || "");
        setEmail(data.email || "")
        setDocumentNumber(data.document_number || "");
        setBirthdate(convertDateToISO(data.birthdate?.toString() || ""));
        setBirthdateString(data.birthdate?.toString() || "");
        setProfessionalOccupation(data.professional_occupation || "");
        setDdd(data.ddd || "");
        setPhoneNumber(data.phone_number || "");
        setStreet(data.address.street || "");
        setNeighborhood(data.address.neighborhood || "");
        setCity(data.address.city || "");
        setState(data.address.state || "");
        setNumber(data.address.number || "");
        setZip(data.address.zip || "");
        setComplementary(data.address.complementary || "");
        setReferencePoint(data.address.reference_point || "");
        setMonthlyIncome(data.monthly_income || 0);
        data.document_number && setIsValid(isValidCPF(data.document_number));

        initialState = data.address.state || ""
    }

    let { id } = useParams();

    const navigate = useNavigate();
    function handleNavigate(path: string) {
        navigate(path)
    }


    const toast = useToast();

    const handleChangeCpf = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setDocumentNumber(value);
        setIsValid(isValidCPF(value));
    };

    const handleCreateOrganizer = () => {
        const documentIsValid = isValidCPF(document_number);

        if (state.length === 0) {
            toast({
                title: "Estado inválido",
                description: "Por favor, selecione um estado",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (city.length === 0){
            toast({
                title: "Cidade inválida",
                description: "Por favor, selecione uma cidade",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (!documentIsValid) {
            toast({
                title: "CPF inválido",
                description: "Por favor, insira um CPF válido.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }
        setIsLoading(true);
        const data = {
            organizer_dto: {
                name: name,
                email: email,
                document_number: document_number,
                mother_name: '',
                birthdate: birthdate ? birthdateString : null,
                professional_occupation: professional_occupation,
                ddd: ddd,
                phone_number: phone_number,
                phone_type: 'mobile',
                monthly_income: monthly_income,
                company_id: organizer.data.company_id
            },
            address_dto: {
                street: street,
                neighborhood: neighborhood,
                city: city,
                state: state,
                number: number,
                zip: zip,
                latitude: '-27.590824',
                longitude: "-48.551262",
                complementary: complementary,
                reference_point: reference_point
            }
        } as IOrganizerUpdateDto;
        updateOrganizer({ organizerId: Number(id), data }).then(async (response) => {
            await new Promise((resolve, reject) => {
                toast({
                    title: "Oganizador atualizado com sucesso",
                    description: response.data.message,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
                setIsLoading(false);
                resolve(response);
            }).then(() => {
                handleNavigate('/company/' + organizer.data.company_id);
            })
        }).catch((error) => {
            toast({
                title: "Erro ao atualizar organizador",
                status: "error",
                description: error.message,
                duration: 9000,
                isClosable: true,
            });
            setIsLoading(false);
        });
    }

    return (
        <>
            <Grid minH="99vh" minW="99vw" p={3} padding={0}>
                <Flex>
                    <Sidebar />
                    <div className="content">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <h1>
                                Dados Pessoais
                            </h1>
                            <form
                                style={{
                                    borderTop: "1px solid #ccc",
                                }}
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleCreateOrganizer();
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-around",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "start",
                                            padding: "10px",
                                            width: "100%",
                                        }}
                                    >

                                        <div
                                            style={{
                                                width: "50%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                                alignItems: "start",
                                                padding: "10px",
                                            }}
                                        >
                                            <FormControl className="itemForm">
                                                <FormLabel>
                                                    Nome
                                                </FormLabel>
                                                <Input value={name} type="text" placeholder="Nome" onChange={(e) => setName(e.target.value)} />
                                            </FormControl>

                                            <FormControl className="itemForm">
                                                <FormLabel>
                                                    Email
                                                </FormLabel>
                                                <Input value={email} type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
                                            </FormControl>
                                            {
                                                <>
                                                    <FormControl className="itemForm">
                                                        <FormLabel>
                                                            CPF
                                                        </FormLabel>
                                                        <Input value={document_number} isInvalid={document_number.length >= 11 && !isValid} _valid={{
                                                            colorScheme: document_number.length >= 11 ? isValid ? "green" : "red.500" : "black",
                                                        }} minLength={11} maxLength={11} required type="number" placeholder="CPF..." onChange={(e) => handleChangeCpf(e)} />
                                                        {
                                                            document_number.length >= 11 ? isValid ? (
                                                                <span style={{ color: 'green' }}></span>
                                                            ) : (
                                                                <span style={{ color: 'red' }}>CPF inválido</span>
                                                            )
                                                                : null
                                                        }
                                                    </FormControl>

                                                    <FormControl className="itemForm">
                                                        <FormLabel>
                                                            Data de Nascimento
                                                        </FormLabel>
                                                        <Input value={birthdate} type="date" placeholder="Data de Nascimento" onChange={(e) => {
                                                            let dayNumber: number = new Date(e.target.value).getDate() + 1;
                                                            let monthNumber: number = new Date(e.target.value).getMonth() + 1;
                                                            let yearNumber: number = new Date(e.target.value).getFullYear();
                                                            let dayString: string = dayNumber.toString();
                                                            let monthString: string = monthNumber.toString();
                                                            let yearString: string = yearNumber.toString();
                                                            if (dayNumber < 10) {
                                                                dayString = `0${dayNumber}`;
                                                            }
                                                            if (monthNumber < 10) {
                                                                monthString = `0${monthNumber}`;
                                                            }
                                                            let fullDate: string = `${dayString}/${monthString}/${yearString}`;

                                                            setBirthdateString(fullDate);
                                                            setBirthdate(e.target.value);
                                                        }} />
                                                    </FormControl>
                                                    <FormControl className="itemForm">
                                                        <FormLabel >
                                                            Profissão
                                                        </FormLabel>
                                                        <Input value={professional_occupation} type="text" placeholder="Profissão" onChange={(e) => setProfessionalOccupation(e.target.value)} />
                                                    </FormControl>
                                                </>
                                            }
                                            <FormControl className="itemForm">
                                                <FormLabel>
                                                    DDD
                                                </FormLabel>
                                                <Input value={ddd} type="text" maxLength={2} minLength={2} placeholder="DDD" onChange={(e) => setDdd(e.target.value)} />
                                            </FormControl>

                                            <FormControl className="itemForm" >
                                                <FormLabel>
                                                    Telefone
                                                </FormLabel>
                                                <Input value={phone_number} type="text" maxLength={9} minLength={9} placeholder="Telefone" onChange={(e) => setPhoneNumber(e.target.value)} />
                                            </FormControl >

                                            <FormControl className="itemForm">
                                                <FormLabel>
                                                    Renda Mensal
                                                </FormLabel>
                                                <Input value={monthly_income} type="number" maxLength={12} placeholder="Renda Mensal" onChange={(e) => setMonthlyIncome(Number(e.target.value))} />
                                            </FormControl>

                                        </div>

                                        <div style={{
                                            width: "50%",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-around",
                                            alignItems: "center",
                                            padding: "10px",
                                        }}>
                                            <h1 style={{
                                                borderBottom: "1px solid #ccc",
                                            }} >
                                                Endereço
                                            </h1>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "space-around",
                                                    width: "100%",
                                                }}
                                            >
                                                <FormControl className="itemForm">
                                                    <FormLabel>
                                                        Rua
                                                    </FormLabel>
                                                    <Input value={street} type="text" placeholder="Rua" onChange={(e) => setStreet(e.target.value)} />
                                                </FormControl>

                                                <FormControl className="itemForm">
                                                    <FormLabel>
                                                        Bairro
                                                    </FormLabel>
                                                    <Input value={neighborhood} type="text" placeholder="Bairro" onChange={(e) => setNeighborhood(e.target.value)} />
                                                </FormControl>

                                                <FormControl>
                                                    <FormLabel>Estado</FormLabel>
                                                    <Select
                                                        className="input"
                                                        name="estado"
                                                        value={state}
                                                        placeholder="Selecione um estado"
                                                        onChange={(e) => handleSelectState(e.target.value)}
                                                        errorBorderColor="pink.400"
                                                    >
                                                        {states.map((state) => (
                                                                    <option key={state.id} value={state.name}>
                                                                        {state.name}
                                                                    </option>
                                                                )
                                                            )
                                                        }
                                                    </Select>
                                                </FormControl>

                                                <FormControl>
                                                    <FormLabel>Cidade</FormLabel>
                                                    <Select
                                                        className="input"
                                                        name="cidade"
                                                        value={city}
                                                        placeholder="Selecione uma cidade"
                                                        onChange={(e) => setCity(e.target.value)}
                                                        errorBorderColor="pink.400"
                                                    >
                                                        {cities.map((city) => (
                                                                    <option key={city.id} value={city.name}>
                                                                        {city.name}
                                                                    </option>
                                                                )
                                                            )
                                                        }
                                                    </Select>
                                                </FormControl>

                                                <FormControl className="itemForm">
                                                    <FormLabel>
                                                        Número
                                                    </FormLabel>
                                                    <Input value={number} type="text" placeholder="Número" onChange={(e) => setNumber(e.target.value)} />
                                                </FormControl>

                                                <FormControl className="itemForm">
                                                    <FormLabel>
                                                        CEP
                                                    </FormLabel>
                                                    <Input value={zip} type="text" placeholder="CEP" onChange={(e) => setZip(e.target.value)} />
                                                </FormControl>

                                                <FormControl className="itemForm">
                                                    <FormLabel>
                                                        Complemento
                                                    </FormLabel>
                                                    <Input value={complementary} type="text" placeholder="Complemento" onChange={(e) => setComplementary(e.target.value)} />
                                                </FormControl>

                                                <FormControl className="itemForm">
                                                    <FormLabel>
                                                        Ponto de Referência
                                                    </FormLabel>
                                                    <Input value={reference_point} type="text" placeholder="Ponto de Referência" onChange={(e) => setReferencePoint(e.target.value)} />
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-around",
                                        alignItems: "center",
                                        borderTop: "1px solid #ccc",
                                        padding: "10px",
                                        marginTop: "20px",
                                        width: "100%",
                                    }}
                                >
                                    <Button
                                        colorScheme="red"
                                        style={{
                                            width: "100%",
                                            marginRight: "10px"
                                        }}
                                        onClick={() => {
                                            if (!isLoading) {
                                                handleNavigate('/company/' + organizer.data.company_id);
                                            }
                                        }}
                                        height={50}
                                    >
                                        {isLoading ? <CircularProgress
                                            isIndeterminate
                                            color="blue"
                                            size="24px"
                                        /> : 'Cancelar'}
                                    </Button>

                                    <Button
                                        colorScheme="green"
                                        width={"100%"}
                                        height={"50px"}
                                        type="submit"
                                    >
                                        {
                                            isLoading ? <CircularProgress
                                                isIndeterminate
                                                color="blue"
                                                size="24px"
                                            /> : 'Salvar'
                                        }
                                    </Button>
                                </div>
                            </form >
                        </div >
                    </div>
                </Flex>
            </Grid>
        </>
    );
}