import { Button, Flex, Grid, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import './style.css';
import { useEffect, useState } from "react";
import { OrganizerForm } from "./Create/OrganizerForm";
import Sidebar from "../../../components/Sidebar";
import { IResponseCreateContaBancaria } from "../../../interfaces/banckaccount-interface";
import { ICreateCompany, IResponseCompanyVerify } from "../../../interfaces/company-interface";
import { IContractDto } from "../../../interfaces/contract-interface";
import { IOrganizerDto } from "../../../interfaces/organizer-interface";
import { Header } from "../../../components/Header";
import { useLocation } from "react-router-dom";
import { CompanyForm } from "./Create/CompanyForm";
import { ContaBancariaForm } from "./Create/BankAccount";
import { ContractForm } from "./Create/ContractForm";
import { verifyCompany } from "../../../services/api";


export const FormsPage = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [companyId, setCompanyId] = useState<number>(0);
    const [company, setCompany] = useState<ICreateCompany>({} as ICreateCompany);
    const [organizer, setOrganizer] = useState<IOrganizerDto>({} as IOrganizerDto);
    const [contaBancaria, setContaBancaria] = useState<IResponseCreateContaBancaria>({} as IResponseCreateContaBancaria);
    const [contaBancariaId, setContaBancariaId] = useState<string>("0");
    const [organizerId, setOrganizerId] = useState<number>(0);
    const [contractId, setContractId] = useState<number>(0);
    const [contract, setContract] = useState<IContractDto>({} as IContractDto);

    const handleTabsChange = (tabIndex: number) => {
        setTabIndex(tabIndex);
    }

    const handleSetCompany = async (data: IResponseCompanyVerify) => {
        const { company } = data.data;
        setCompany({
            company_dto: {
                email: company.email,
                document_number: company.document_number,
                type: company.type,
                ddd: company.ddd,
                number_phone: company.number_phone,
                type_phone: company.type_phone,
                company_name: company.company_name,
                trading_name: company.trading_name,
                annual_revenue:company.annual_revenue,
                name: company.name,
                birthdate: company.birthdate,
                monthly_income: company.monthly_income,
                professional_occupation: company.professional_occupation,
                franchise_id: Number(company.franchise_id),
            },
            address_dto: {
                street: company.address.street,
                neighborhood: company.address.neighborhood,
                city: company.address.city,
                state: company.address.state,
                number: company.address.number,
                zip: company.address.zip,
                latitude: company.address.latitude,
                longitude: company.address.longitude,
                complementary: company.address.complementary,
                reference_point: company.address.reference_point,
            }
        })

        setCompanyId(Number(company.id));
    }

    const handleSetContaBancaria = async (data: IResponseCompanyVerify) => {
        const { company } = data.data;
        if (company.bankAccounts && company.bankAccounts.length > 0) {
            setContaBancariaId(company.bankAccounts[0].id)
        }
    }

    const location = useLocation()
    useEffect(() => {
        if (location.state === undefined || location.state === null) return
        const { data }: IResponseCompanyVerify = location.state;
        handleSetCompany({
            data: data,
            statusCode: 400,
            error: false,
            message: "has pendencies"
        });

        const organizerPending = data.pendencias.find((pendencia) => pendencia.pending === 'organizer');
        const contaBancariaPending = data.pendencias.find((pendencia) => pendencia.pending === 'bankAccount');
        const contractPending = data.pendencias.find((pendencia) => pendencia.pending === 'contract');

        if (organizerPending) {
            // handleSetOrganizer({
            //     data: data,
            //     statusCode: 400,
            //     error: false,
            //     message: "has pendencies"
            // });
            handleSetCompany({
                data: data,
                statusCode: 400,
                error: false,
                message: "has pendencies"
            });
            setTabIndex(1);
        }else if(contaBancariaPending){
            if (data && data.company && data.company.organizers && data.company.organizers.length > 0) {
                setOrganizerId(data.company.organizers[0].id);
            }

            handleSetContaBancaria({
                data: data,
                statusCode: 400,
                error: false,
                message: "has pendencies"
            });
            setTabIndex(2);

        }else if(contractPending){
            if (data && data.company && data.company.organizers && data.company.organizers.length > 0) {
                setOrganizerId(data.company.organizers[0].id);
            }

            handleSetContaBancaria({
                data: data,
                statusCode: 400,
                error: false,
                message: "has pendencies"
            });
            setTabIndex(3);
        }

    }, [location])

    return (
        <>
            <Header />
            <Grid minH="99vh" minW="99vw" >
                <Flex>
                    <Sidebar />
                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        padding: "20px",
                    }}>
                        <div
                            style={{
                                width: "100%",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                                flexDirection: "column",
                            }}
                        >
                            <Tabs
                                variant='enclosed'
                                index={tabIndex}
                            >
                                <TabList>
                                    <Tab
                                        style={{ fontWeight: companyId !== 0 ? 'bold' : 'normal', color: companyId !== 0 ? 'green' : 'black' }}
                                    >
                                        Empresa{companyId !== 0 && ' ✔'}
                                    </Tab>
                                    <Tab
                                        style={{ fontWeight: organizerId !== 0 ? 'bold' : 'normal', color: organizerId !== 0 ? 'green' : 'black' }}
                                    >
                                        Organizador{organizerId !== 0 && ' ✔'}
                                    </Tab>
                                    <Tab
                                        style={{ fontWeight: contaBancariaId !== "0" ? 'bold' : 'normal', color: contaBancariaId !== "0" ? 'green' : 'black' }}
                                    >
                                        Conta Bancaria  {contaBancariaId !== "0" && ' ✔'}
                                    </Tab>
                                    <Tab
                                        style={{ fontWeight: contractId !== 0 ? 'bold' : 'normal', color: contractId !== 0 ? 'green' : 'black' }}
                                    >
                                        Contrato{contractId !== 0 && ' ✔'}
                                    </Tab>
                                </TabList>
                                <TabPanels>
                                    <TabPanel>
                                        <CompanyForm
                                            nextTab={() => setTabIndex(1)}
                                            setCompanyIdGlobalForm={setCompanyId}
                                            setCompanyGlobalForm={setCompany}
                                            companyGlobalIsCreated={companyId !== 0}
                                        />
                                        {
                                            companyId !== 0
                                            && <div
                                                style={{
                                                    flexDirection: "row",
                                                    justifyContent: "space-around",
                                                    alignItems: "center",
                                                    borderTop: "1px solid #ccc",
                                                    padding: "10px",
                                                    marginTop: "20px",
                                                    width: "100%",
                                                }}
                                            >
                                                <Flex
                                                    justifyContent="end"
                                                    style={{ marginTop: "20px" }}
                                                >
                                                    <Button
                                                        onClick={() => setTabIndex(1)}
                                                        colorScheme="blue"
                                                        height={50}
                                                        style={{ width: "100%" }}
                                                    >
                                                        Próximo
                                                    </Button>
                                                </Flex>
                                            </div>
                                        }
                                    </TabPanel>
                                    <TabPanel>
                                        <OrganizerForm
                                            company={company}
                                            setOrganizerGlobalForm={setOrganizer}
                                            setOrganizerIdGlobalForm={setOrganizerId}
                                            companyId={companyId}
                                            nextTab={() => setTabIndex(2)}
                                            backTab={() => setTabIndex(0)}
                                        />
                                        {
                                            organizerId !== 0
                                            && <div
                                                style={{
                                                    flexDirection: "row",
                                                    justifyContent: "space-around",
                                                    alignItems: "center",
                                                    padding: "10px",
                                                    width: "100%",
                                                }}
                                            >
                                                <Flex
                                                    justifyContent="end"
                                                    style={{ marginTop: "20px" }}
                                                >
                                                    <Button
                                                        onClick={() => setTabIndex(0)}
                                                        colorScheme="blue"
                                                        height={50}
                                                        style={{ width: "100%", marginRight: "10px" }}
                                                    >
                                                        Voltar
                                                    </Button>
                                                    <Button
                                                        onClick={() => setTabIndex(2)}
                                                        colorScheme="green"
                                                        height={50}
                                                        style={{
                                                            width: "100%",
                                                            marginRight: "10px"
                                                        }}
                                                    >
                                                        Próximo
                                                    </Button>
                                                </Flex>
                                            </div>
                                        }
                                        <Flex
                                            justifyContent="space-between"
                                            alignItems="center"
                                            style={{ marginTop: "20px" }}
                                        >
                                        </Flex>
                                    </TabPanel>
                                    <TabPanel>
                                        <ContaBancariaForm
                                            company_id={companyId}
                                            setContaBancariaGlobal={setContaBancaria}
                                            setContaBancariaIdGlobal={setContaBancariaId}
                                            company={company}
                                            nextTab={() => setTabIndex(3)}
                                            backTab={() => setTabIndex(1)}
                                        />
                                        {
                                            contaBancariaId !== "0"
                                            && (

                                                <div
                                                    style={{
                                                        flexDirection: "row",
                                                        justifyContent: "space-around",
                                                        alignItems: "center",
                                                        padding: "10px",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <Flex
                                                        justifyContent="end"
                                                        style={{ marginTop: "20px" }}
                                                    >
                                                        <Button
                                                            colorScheme="blue"
                                                            height={50}
                                                            style={{ width: "100%", marginRight: "10px" }}
                                                            onClick={() => setTabIndex(1)}>
                                                            Voltar
                                                        </Button>
                                                        <Button
                                                            colorScheme="green"
                                                            height={50}
                                                            style={{
                                                                width: "100%",
                                                                marginRight: "10px"
                                                            }}
                                                            onClick={() => setTabIndex(3)}>
                                                            Próximo
                                                        </Button>
                                                    </Flex>
                                                </div>
                                            )
                                        }
                                    </TabPanel>
                                    <TabPanel>
                                        <ContractForm
                                            company_id={companyId}
                                            setContractprops={setContract}
                                            backTab={() => setTabIndex(2)}
                                            finalizer={() => handleTabsChange(1)}
                                        />
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </div>
                    </div >
                </Flex>
            </Grid >
        </>
    );
}