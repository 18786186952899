import { AddIcon } from "@chakra-ui/icons";
import { Button, Flex, Grid, GridItem, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import Drawer from "../../components/Drawer";
import Sidebar from "../../components/Sidebar";
import EventTable from "./components/NotificationTable";
import NotificationForm from './components/NotificationForm';

import "./style.css";
import { Header } from "../../components/Header";

const Notification: React.FC = () => {
  const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure()

  return (
    <>
      <Grid
        templateColumns="auto 1fr"
        templateRows="auto 1fr"
        minH="100vh"
        p={0}
        gap={0}
      >
        <GridItem
          as="aside" 
          colSpan={1} 
          rowSpan={2} 
          bg="gray.100" 
          h="100vh"
          position="sticky"
          top="0"
        >
          <Sidebar />
        </GridItem>

        <GridItem as="header" colStart={2} colSpan={1} bg="gray.200" w="100%">
          <Header />
        </GridItem>

        <GridItem colStart={2} rowStart={2} w="100%" h="100%">
          <Flex>
            <div className="content">
              <div style={{marginTop: '-5%'}} className="table-header">
                <Text fontSize={"4xl"}>Notificações</Text>
                <Button leftIcon={<AddIcon />} colorScheme="blue" variant="solid" onClick={onOpenDrawer}>
                  Novo
                </Button>
              </div>
              <EventTable />
            </div>
          </Flex>
        </GridItem>
      </Grid>

      <Drawer
          title={'Nova notificação'}
          isOpen={isOpenDrawer}
          onClose={onCloseDrawer}
          cancelText={'Cancelar'}
          confirmText={'Salvar'}
          children={<NotificationForm onSubmit={() => { }} />}
        />
    </>
  );
};

export default Notification;
