import { ButtonGroup, Switch, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import { ICity } from "../../interfaces/city-interface"

type PropsType = {
    allSCities: ICity[]
    toggleStatusCity: (cityId: number, isActive: boolean) => Promise<void>
}

export const DataTable = (props: PropsType) => {
    const {
        allSCities,
        toggleStatusCity
    } = props

    return(
        <Table variant="simple">
            <Thead>
                <Tr>
                    <Th width={"0"}>ID</Th>
                    <Th >Nome</Th>
                    <Th>Status</Th>
                    <Th>Ações</Th>
                </Tr>
            </Thead>

            <Tbody className="tr">
                { allSCities.map(city => (
                        <Tr>
                            <Td>{city.id}</Td>
                            <Td>{city.name}</Td>
                            <Td><Text fontWeight={'bold'} color={city.is_active ? 'green' : 'red'}>{city.is_active ? 'ATIVO' : 'INATIVO'}</Text></Td>
                            <ButtonGroup
                                width={'100%'}
                            >
                            <Switch
                                title={city.is_active ? "Desativar cidade" : "Ativar cidade"}
                                isChecked={city.is_active} 
                                onChange={() => toggleStatusCity(city.id, city.is_active)}
                                colorScheme="teal"
                                size="lg" 
                            />
                            </ButtonGroup>
                        </Tr>
                    ))
                }
            </Tbody>
        </Table>
    )
}