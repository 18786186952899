import { useEffect, useState } from "react"
import { Flex, Grid, GridItem, Text } from "@chakra-ui/react"
import { Header } from "../../components/Header"
import Sidebar from "../../components/Sidebar"
import { CustomSpinner } from "../../components/CustomSpinner/custom-spinner"
import { toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { listCitiesByStateId, searchCities, toggleActiveCities } from "../../services/api"
import { ICity } from "../../interfaces/city-interface"
import { useParams } from "react-router-dom"
import { DataTable } from "./data-table"
import { SearchInput } from "../../components/SearchInput/search-input"


export const Cities = () => {

    const [allCities, setAllCities] = useState<ICity[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [searchInput, setSearchInput] = useState<string>('')

    const { state_id } = useParams()

    async function toggleStatusCity(cityId: number, isActive: boolean){
        setIsLoading(true)
        await toggleActiveCities(cityId, !isActive)
        .then(async ({data}) => {
            await getAllCities()
            toast.success(`Cidade ${isActive ? 'DESATIVADA' : 'ATIVADA'} com sucesso`)
        })
        .catch(error => {
            console.log(error)
            toast.error('Ocorreu um error ao atualizar status da cidade!')
        })
        setIsLoading(false)
    }

    async function getAllCities(){
        setIsLoading(true)
        try {
            const {data} = await listCitiesByStateId(Number(state_id))
            const cities = data.data
            setAllCities(cities)
            setIsLoading(false)
            
        } catch (error) {
            console.log(error)
            toast.error('Ocorreu um error ao listar todos os estados!')
            setIsLoading(false)
        }
    }

    const handleSearch = async () => {
        setIsLoading(true)
        await searchCities(Number(state_id), searchInput)
        .then(({data}) => {
            const cities = data.data
            setAllCities(cities)
        })
        .catch(error => {
            console.log(error)
            toast.error('Ocorreu um error ao listar as cidades!')
        })
        setIsLoading(false)
    }

    useEffect(() => {
        if(searchInput.length === 0){
            getAllCities()
        }
    },[searchInput])

    useEffect(() => {
        getAllCities()
    },[])

    return(
        <>
            <Grid
                templateColumns="auto 1fr"
                templateRows="auto 1fr"
                minH="100vh"
                p={0}
                gap={0}
            >

                <GridItem
                    as="aside" 
                    colSpan={1} 
                    rowSpan={2} 
                    bg="gray.100" 
                    h="100vh"
                    position="sticky"
                    top="0"
                >
                    <Sidebar />
                </GridItem>

                <GridItem as="header" colStart={2} colSpan={1} bg="gray.200" w="100%">
                    <Header />
                </GridItem>

                <GridItem colStart={2} rowStart={2} w="100%" h="100%">
                    <Flex>
                    <div className="content">
                        <div style={{marginTop: '-5%'}} className="table-header">
                            <Text fontSize={"4xl"}>Cidades</Text>
                        </div>

                        <div
                            style={{
                                marginBottom: '50px'
                            }}
                        >
                            <SearchInput
                                searchInput={searchInput}
                                setSearchInput={setSearchInput}
                                handleSearch={handleSearch}
                                placeholder="Busque por uma cidade"
                            />
                        </div>

                        { isLoading 
                        ? <CustomSpinner/> 
                        : 
                            <DataTable
                                allSCities={allCities}
                                toggleStatusCity={toggleStatusCity}
                            />
                        }
                        
                    </div>
                    </Flex>
                </GridItem>

            </Grid>
        </>
    )
}