import { Button, Flex, FormControl, FormLabel, Grid, Input, theme, useToast } from '@chakra-ui/react'
import  { useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import { getCompanyByOrganize, signin } from '../../services/api'
import { setCompany, setToken, setUser } from '../../services/auth'
import Logo from '../../assets/logo.png';
import './styles.css';
export default function Signin() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    // const navigate = useNavigate()

    const toast = useToast()

    // function handleNavigate() {
    //     navigate('/signup')
    // }

    async function handleClick() {

        try {
            const response = await signin(email, password)
            setToken(response.data.accessToken)
            setUser(response.data.user)
            getCompanyByOrganize(response.data.user.id).then(res => {
                setCompany(res.data.data)
            })
            window.location.reload()

            toast({
                title: "Login efetuado com sucesso.",
                status: "success",
                duration: 4000,
                isClosable: true,
            })
        } catch (error) {
            toast({
                title: "Credenciais inválidas.",
                status: "error",
                duration: 4000,
                isClosable: true,
            })
        }

    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleClick(); // Chama a função de login quando "Enter" é pressionado
        }
    };

    return (
        <Grid minH="100vh" minW="100vw" p={3} padding={0}>

            <Flex justifyContent={'center'} alignItems={'center'} minH='100vh' width='100vw' backgroundColor={theme.colors.green}>

                <FormControl minH='50vh' width={'25vw'} backgroundColor={theme.colors.green}>
                    <div className='img'>
                        <img src={Logo} alt="Logo" width="200" height="200" className='imagestyle' />
                    </div>
                    <FormControl>
                        <FormLabel>Email</FormLabel>
                        <Input onKeyDown={handleKeyDown} placeholder='Email' type={'email'} value={email} onChange={(e) => setEmail(e.target.value)} focusBorderColor='pink.700' />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Senha</FormLabel>
                        <Input onKeyDown={handleKeyDown} placeholder='****' type={'password'} value={password} onChange={(e) => setPassword(e.target.value)} focusBorderColor='pink.700' />
                    </FormControl>

                    {/* <Text mt={5} color='GrayText' cursor={'pointer'} onClick={handleNavigate} _hover={

                        { color: 'purple', textDecorationColor: 'purple', fontWeight: 'bold', fontSize: '1.1rem', }
                    } >Criar Conta</Text> */}

                    <Button variant={'outline'} marginTop={5} width={'25vw'} onClick={handleClick} style={{
                        backgroundColor: 'purple', color: 'white'
                    }} >Entrar</Button>
                </FormControl>
            </Flex>
        </Grid>
    )
}
