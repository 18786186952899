import { useEffect, useRef, useState } from "react";
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    IconButton,
    useDisclosure,
    useToast,
    Flex,
    Select,
    Text,
    Tooltip,
    Button,
} from "@chakra-ui/react";
import Alert from "../../../components/Alert";
import { listFranchise, listFranchiseWithPagination, verifyStatusFranchise, } from "../../../services/api";
import { IFranchise, IFranchiseResponse, IResponseVerifyFranchise } from "../../../interfaces/franchise-interface";
import { ComponentStatusFranchise } from "../Components/FranchiseStatus";
import { useNavigate } from "react-router-dom";
import { FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight } from "react-icons/fa";

export default function FranchiseTable({
}: {
    }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = useRef();

    const [franchiseList, setFranchiseList] = useState<IFranchiseResponse>({} as IFranchiseResponse);
    const [metaPageSize, setMetaPageSize] = useState<number>(10);
    const toast = useToast();
    const navigate = useNavigate();

    const handleNavigate = (path: string, state?: any) => {
        navigate(path, { state });
    }

    useEffect(() => {
        // listFranchise().then((response) => {
        //     if (response.data.statusCode !== 200) {
        //         console.log(response.data.message);
        //         return toast({
        //             title: "Erro ao buscar franquias",
        //             description: response.data.message,
        //             status: "error",
        //             duration: 9000,
        //             isClosable: true,
        //         });
        //     }
        //     setFranchiseList(response.data.data);
        // })

        listFranchiseWithPagination({
            limit: metaPageSize,
            page: 1,
        }).then((response) => {
            setFranchiseList(response.data);
        })
    }, [])

    const handleVerifyFranchise = async (franchiseId: number) => {
        const response = await verifyStatusFranchise(franchiseId);
        const { statusCode, data } = response.data;
        const isHasPendenci = handleVerifyHasPendecies(response.data);
        return isHasPendenci ? response.data : false;
    }

    const handleVerifyHasPendecies = (data: IResponseVerifyFranchise) => {
        const adminFranchise = data.data.admins;
        if (adminFranchise.length <= 0) {
            return true;
        }
        const banckAccountFranchise = data.data.bankAccounts;
        if (banckAccountFranchise.length <= 0) {
            return true;
        }
        return false;
    }

    function handleConfirmDelete() {
    }

    const nextPage = () => {
        listFranchiseWithPagination({
            limit: metaPageSize,
            page: franchiseList.data.meta?.currentPage + 1,
        }).then((response) => {
            setFranchiseList(response.data);
        }).catch((error) => {
            console.log("error", error);
        });
    }

    const backPage = () => {
        listFranchiseWithPagination({
            limit: metaPageSize,
            page: franchiseList.data.meta?.currentPage - 1,
        }).then((response) => {
            setFranchiseList(response.data);
        }).catch((error) => {
            console.log("error", error);
        });
    }

    const firstPage = () => {
        listFranchiseWithPagination({
            limit: metaPageSize,
            page: 1,
        }).then((response) => {
            setFranchiseList(response.data);
        }).catch((error) => {
            console.log("error", error);
        });
    }

    const lastPage = () => {
        listFranchiseWithPagination({
            limit: metaPageSize,
            page: franchiseList.data.meta?.totalPages,
        }).then((response) => {
            setFranchiseList(response.data);
        }).catch((error) => {
            console.log("error", error);
        });
    }

    const limitListFranchise = (limit: number) => {
        listFranchiseWithPagination({
            limit: limit,
            page: franchiseList.data.meta?.currentPage,
        }).then((response) => {
            setFranchiseList(response.data);
        }).catch((error) => {
            console.log("error", error);
        });
    }


    return (
        <>
            <TableContainer className="table-container">
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th width={"0"}></Th>
                            <Th width={"20"}>Nome</Th>
                            <Th>Email</Th>
                            <Th>Telefone</Th>
                        </Tr>
                    </Thead>
                    <Tbody className="tr">
                        {
                            franchiseList?.data?.itens?.length === 0 ? (
                                <Tr>
                                    <Td colSpan={4}>Nenhuma franquia encontrada...</Td>
                                </Tr>
                            ) : (
                                franchiseList?.data?.itens?.map((franchise) => (
                                    <Tr
                                        onClick={async () => {
                                            const response = await handleVerifyFranchise(Number(franchise.id));
                                            if (response) {
                                                handleNavigate('/franchise/form', { data: response.data })
                                            } else {
                                                handleNavigate(`/franchise/${franchise.id}`)
                                            }
                                        }}
                                    >
                                        <Td>
                                            <ComponentStatusFranchise franchiseId={Number(franchise.id)} />
                                        </Td>
                                        <Td>{franchise.company_name}</Td>
                                        <Td>{franchise.email}</Td>
                                        <Td>
                                            <div>
                                                {franchise.ddd} {franchise.number_phone}
                                            </div>
                                        </Td>
                                    </Tr>
                                ))
                            )
                        }
                    </Tbody>
                </Table>
                <Flex justifyContent="space-between" m={4} alignItems={"center"}>
                    <Flex>
                        <Tooltip label="Página inicial">
                            <IconButton
                                isDisabled={!franchiseList.data?.meta?.hasPreviousPage}
                                icon={<FaAngleDoubleLeft />}
                                aria-label="Página inicial"
                                margin={4}
                                onClick={firstPage}
                            />
                        </Tooltip>
                        <Tooltip label="Página anterior">
                            <IconButton
                                isDisabled={!franchiseList.data?.meta?.hasPreviousPage}
                                icon={<FaAngleLeft />}
                                aria-label="Página anterior"
                                margin={4}
                                onClick={backPage}
                            />
                        </Tooltip>
                    </Flex>

                    <Flex
                        alignItems={'center'}
                    >
                        <Text flexShrink={"0"} mr={8} >
                            Página{" "}
                            <Text fontWeight="bold" as="span">
                                {franchiseList.data?.meta?.currentPage}
                            </Text>{" "}
                            de{" "}
                            <Text fontWeight="bold" as="span">
                                {franchiseList.data?.meta?.totalPages}
                            </Text>
                        </Text>
                    </Flex>
                    <Flex alignItems="center">
                        <Select
                            w={32}
                            value={metaPageSize}
                            onChange={(e) => {
                                setMetaPageSize(Number(e.target.value));
                                limitListFranchise(Number(e.target.value));
                            }}
                        >
                            {[10, 20, 30, 40, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Mostrar {pageSize}
                                </option>
                            ))}
                        </Select>
                    </Flex>

                    <Flex
                        alignItems={'center'}
                    >
                        <Tooltip label="Próxima página">
                            <IconButton
                                icon={<FaAngleRight />}
                                aria-label="Próxima página"
                                margin={4}
                                onClick={nextPage}
                                isDisabled={!franchiseList.data?.meta?.hasNextPage}
                            />
                        </Tooltip>
                        <Tooltip label="Última página">
                            <IconButton
                                icon={<FaAngleDoubleRight />}
                                aria-label="Última página"
                                margin={4}
                                onClick={lastPage}
                                isDisabled={!franchiseList.data?.meta?.hasNextPage}
                            />
                        </Tooltip>
                    </Flex>
                </Flex>
            </TableContainer>

            <Alert
                title={"Deletar franquia"}
                confirmActionMessage={"Tem certeza que deseja deletar este franquia ?"}
                confirmActionButtonText={"Deletar"}
                cancelActionButtonText={"Cancelar"}
                isOpen={isOpen}
                onClose={onClose}
                cancelRef={cancelRef}
                onConfirm={handleConfirmDelete}
                confirmButtonText={""}
            />
        </>
    );
}
