import { Button, ButtonGroup, Switch, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react"
import { IStates } from "../../interfaces/states-interface"
import { FaCity } from "react-icons/fa"
import { useNavigate } from "react-router-dom"

type PropsType = {
    allStates: IStates[]
    toggleStatusState: (stateId: number, isActive: boolean) => Promise<void>
}

export const DataTable = (props: PropsType) => {
    const {
        allStates,
        toggleStatusState
    } = props

    const navigate = useNavigate();

    return(
        <Table variant="simple">
            <Thead>
                <Tr>
                    <Th width={"0"}>ID</Th>
                    <Th >Nome</Th>
                    <Th>Sigla</Th>
                    <Th>Status</Th>
                    <Th>Ações</Th>
                </Tr>
            </Thead>

            <Tbody className="tr">
                { allStates.map(state => (
                        <Tr>
                            <Td>{state.id}</Td>
                            <Td>{state.name}</Td>
                            <Td>{state.acronym}</Td>
                            <Td><Text fontWeight={'bold'} color={state.is_active ? 'green' : 'red'}>{state.is_active ? 'ATIVO' : 'INATIVO'}</Text></Td>
                            <ButtonGroup
                                width={'100%'}
                            >
                            <Switch
                                title={state.is_active ? "Desativar estado" : "Ativar estado"}
                                isChecked={state.is_active} 
                                onChange={() => toggleStatusState(state.id, state.is_active)}
                                colorScheme="teal"
                                size="lg" 
                            />

                            <Button
                                onClick={() => navigate(`${state.id}/cities`)}
                                title="Listar cidades"
                            >
                                <FaCity/>
                            </Button>
                            </ButtonGroup>
                        </Tr>
                    ))
                }
            </Tbody>
        </Table>
    )
}