import { Button, Input } from "@chakra-ui/react"

type SearchInputType = {
    searchInput: string
    setSearchInput: React.Dispatch<React.SetStateAction<string>>
    currentPage?: number
    setCurrentPage?: React.Dispatch<React.SetStateAction<number>>
    handleSearch: () => Promise<void>,
    placeholder?: string
    inputType?: string
    width?: string
}

export const SearchInput = (props: SearchInputType) => {

    const {
        searchInput,
        setSearchInput,
        currentPage,
        setCurrentPage,
        handleSearch,
        placeholder,
        inputType,
        width
    } = props

    return (
        <div
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Input
                type={inputType ? inputType : 'text'}
                width={width ?? '25rem'}
                placeholder={placeholder ? placeholder : "Digite o que você procura"}
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <Button
                colorScheme="blue"
                marginLeft={5}
                onClick={(e) => {
                  if(currentPage && currentPage !== 1){
                    setCurrentPage && setCurrentPage(1)
                  }else {
                    handleSearch()
                  }
                }}
              >
                Buscar
              </Button>
            </div>
    )
}