import { Button, Flex, Grid, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Sidebar from "../../../components/Sidebar";
import { FranchiseForm } from "./Create/FranchiseForm";
import { AdminForm } from "./Create/AdminForm";
import { IResponseCreateAdmin } from "../../../interfaces/admin-interface";
import { ICreateFranchise, IResponseCreateFranchise, IResponseVerifyFranchise } from "../../../interfaces/franchise-interface";
import { ContaBancariaFranquiaForm } from "./Create/BanckAccountForm";
import { IResponseCreateBankAccountFranchise } from "../../../interfaces/bank-account";
import { useLocation } from "react-router-dom";

export const FranchiseFormsPage = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [franchiseCreatedDto, setFranchiseCreatedDto] = useState<ICreateFranchise>({} as ICreateFranchise);
    const [franchiseCreated, setFranchiseCreated] = useState<IResponseCreateFranchise>({} as IResponseCreateFranchise);
    const [adminGlobal, setAdminGlobal] = useState<IResponseCreateAdmin>({} as IResponseCreateAdmin);
    const [contaBancariaGlobal, setContaBancariaGlobal] = useState<IResponseCreateBankAccountFranchise>({} as IResponseCreateBankAccountFranchise);

    const location = useLocation()

    useEffect(() => {
        if (location.state === undefined || location.state === null) return
        const { data }: IResponseVerifyFranchise = location.state;
        if (data.id) {
            setFranchiseCreated({
                data: {
                    id: Number(data.id),
                    name: data.name,
                    email: data.email,
                    type: data.type,
                    created_at: new Date(data.created_at),
                    address_id: data.address_id,
                    ddd: data.ddd,
                    type_phone: data.type_phone,
                    admins: null,
                    deleted_at: null,
                    document_number: data.document_number,
                    annual_revenue: data.annual_revenue,
                    bankAccounts: null,
                    companies: null,
                    birthdate: data.birthdate,
                    company_name: data.company_name,
                    monthly_income: Number(data.monthly_income),
                    professional_occupation: data.professional_occupation,
                    recipient_id: data.recipient_id,
                    trading_name: data.trading_name,
                    updated_at: new Date(data.updated_at),
                    number_phone: data.number_phone,
                    address: {
                        city: data.address.city,
                        id: data.address.id,
                        number: data.address.number,
                        latitude: data.address.latitude,
                        longitude: data.address.longitude,
                        state: data.address.state,
                        street: data.address.street,
                        neigborhood: data.address.neighborhood,
                        zip: data.address.zip
                    }
                }, error: false, message: '', statusCode: 200
            });
            setFranchiseCreatedDto({
                franchise_dto: {
                    annual_revenue: data.annual_revenue,
                    birthdate: data.birthdate,
                    company_name: data.company_name,
                    document_number: data.document_number,
                    email: data.email,
                    monthly_income: Number(data.monthly_income),
                    name: data.name,
                    professional_occupation: data.professional_occupation,
                    trading_name: data.trading_name,
                    type: data.type,
                    ddd: data.ddd,
                    number_phone: data.number_phone,
                    type_phone: data.type_phone,
                }, address_dto: {
                    city: data.address.city,
                    neighborhood: data.address.neighborhood,
                    state: data.address.state,
                    street: data.address.street,
                    number: data.address.number,
                    zip: data.address.zip,
                    latitude: data.address.latitude.toString(),
                    longitude: data.address.longitude.toString(),
                    complementary: data.address.complementary,
                    reference_point: data.address.reference_point,
                }
            })
            setTabIndex(1)
        }
        if (data.admins.length > 0) {
            setAdminGlobal({
                data: {
                    id: data.admins[0].id,
                    email: data.admins[0].email,
                    document_number: data.admins[0].document_number,
                    birthdate: data.admins[0].birthdate,
                    name: data.admins[0].name,
                    created_at: null,
                    ddd: data.admins[0].ddd,
                    franchise_id: Number(data.admins[0].franchise_id),
                    franchise: null,
                    monthly_income: null,
                    mother_name: null,
                    phone_number: data.admins[0].phone_number,
                    phone_type: data.admins[0].phone_type,
                    professional_occupation: data.admins[0].professional_occupation,
                    type: data.admins[0].type,
                    user_id: data.admins[0].user_id,
                    self_declared_legal_representative: null
                }, error: false, message: '', statusCode: 200
            });
            setTabIndex(2)
        }
        if (data.bankAccounts.length > 0) {
            setContaBancariaGlobal({
                data: {
                    code: data.bankAccounts[0].code,
                    created_at: data.bankAccounts[0].created_at,
                    document: data.bankAccounts[0].holder_document,
                    name: data.bankAccounts[0].holder_name,
                    id: data.bankAccounts[0].id,
                    email: '',
                    payment_mode: '',
                    status: '',
                    type: '',
                    automatic_anticipation_settings: {
                        enabled: false,
                        volume_percentage: 0,
                        type: '',
                        delay: 0,
                    },
                    gateway_recipients: null,
                    default_bank_account: null,
                    register_information: null,
                    transfer_settings: null,
                    updated_at: '',
                }, error: false, message: '', statusCode: 200
            });
        }
    }, [])

    const handleTabsChange = (tabIndex: number) => {
        setTabIndex(tabIndex);
    }

    return (
        <Grid minH="100vh" minW="100vw" >
            <Flex>
                <Sidebar />
                <div style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "20px",
                }}>
                    <div
                        style={{
                            width: "100%",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                            flexDirection: "column",
                        }}
                    >
                        <Tabs
                            variant='enclosed'
                            index={tabIndex}
                        >
                            <TabList>
                                <Tab
                                    style={{ fontWeight: franchiseCreated?.data?.id ? 'bold' : 'normal', color: franchiseCreated?.data?.id ? 'green' : 'black' }}
                                >
                                    Franquia{franchiseCreated?.data?.id && ' ✔'} 
                                </Tab> 

                                <Tab
                                    style={{ fontWeight: adminGlobal?.data?.id ? 'bold' : 'normal', color: adminGlobal?.data?.id ? 'green' : 'black' }}
                                >
                                    Administrador {adminGlobal?.data?.id && ' ✔'}
                                </Tab>
                                

                                <Tab
                                    style={{ fontWeight: contaBancariaGlobal?.data?.id ? 'bold' : 'normal', color: contaBancariaGlobal?.data?.id ? 'green' : 'black' }}
                                >
                                    Conta Bancaria {contaBancariaGlobal?.data?.id && ' ✔'}
                                </Tab>
                            </TabList>

                            <TabPanels>
                                <TabPanel>
                                <FranchiseForm
                                    nextTab={setTabIndex}
                                    setFranchiseGlobalForm={setFranchiseCreatedDto}
                                    setFranchiseGlobal={setFranchiseCreated}
                                />
                                {
                                    franchiseCreated?.data?.id && <div
                                        style={{
                                            flexDirection: "row",
                                            justifyContent: "space-around",
                                            alignItems: "center",
                                            borderTop: "1px solid #ccc",
                                            padding: "10px",
                                            marginTop: "20px",
                                            width: "100%",
                                        }}
                                    >
                                        <Flex
                                            justifyContent="end"
                                            style={{ marginTop: "20px" }}
                                        >
                                            <Button
                                                onClick={() => setTabIndex(1)}
                                                colorScheme="blue"
                                                height={50}
                                                style={{ width: "100%" }}
                                            >
                                                Próximo
                                            </Button>
                                        </Flex>
                                    </div>
                                }
                                </TabPanel> 

                                <TabPanel>
                                <AdminForm
                                    nextTab={setTabIndex}
                                    franchiseDto={franchiseCreatedDto}
                                    franchiseId={Number(franchiseCreated.data?.id)}
                                    setAdminGlobalForm={setAdminGlobal}
                                />
                                {
                                    adminGlobal?.data?.id && (
                                        <div
                                            style={{
                                                flexDirection: "row",
                                                justifyContent: "space-around",
                                                alignItems: "center",
                                                padding: "10px",
                                                width: "100%",
                                            }}
                                        >
                                            <Flex
                                                justifyContent="end"
                                                style={{ marginTop: "20px" }}
                                            >
                                                <Button
                                                    colorScheme="blue"
                                                    height={50}
                                                    style={{ width: "100%", marginRight: "10px" }}
                                                    onClick={() => setTabIndex(1)}>
                                                    Voltar
                                                </Button>
                                                <Button
                                                    colorScheme="green"
                                                    height={50}
                                                    style={{
                                                        width: "100%",
                                                        marginRight: "10px"
                                                    }}
                                                    onClick={() => setTabIndex(2)}>
                                                    Próximo
                                                </Button>
                                            </Flex>
                                        </div>
                                    )
                                }
                                </TabPanel>

                                <TabPanel>
                                    <ContaBancariaFranquiaForm
                                        franchise={franchiseCreated}
                                        setContaBancariaGlobal={setContaBancariaGlobal}
                                    />
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </div>
                </div >
            </Flex>
        </Grid >
    );
}