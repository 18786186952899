import { AddIcon } from "@chakra-ui/icons";
import { Button, Flex, Grid, GridItem, Text, useDisclosure, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Drawer from "../../components/Drawer";
import Sidebar from "../../components/Sidebar";
import HighlightedEventsTable from "./components/HighlightedEventsTable";

import "./styles.css";
import HighlightedEventsForm from './components/HighlightedEventsForm';
import { listHighlightedEvents, createHighlightedEvent, listHighlightedEventsByOrganizer } from '../../services/api';
import { getUser } from "../../services/auth";
import { IHighlightedEvent } from "../../interfaces/event-interface";
import { Header } from "../../components/Header";

const HighlightedEvents: React.FC = () => {
  const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure()
  const [highlightedEvents, setHighlightedEvents] = useState<IHighlightedEvent[]>([])
  const toast = useToast()

  function list() {
    const user = getUser();
    if (!user.rootUser) {
      listHighlightedEventsByOrganizer(user.id).then(response => {
        setHighlightedEvents(response.data.items)
      })
    } else {
      listHighlightedEvents().then(response => {
        setHighlightedEvents(response.data.items)
      })
    }
  }

  function newHighlightedEvents(data: IHighlightedEvent) {
    createHighlightedEvent(data).then(() => {
      list()
      toast({
        title: "Evento destacado com sucesso.",
        status: "success",
        duration: 4000,
        isClosable: true,
      })
    })
      .catch(() =>
        toast({
          title: 'Erro ao destacar evento',
          description: "Preencha os campos corretamente.",
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      )
  }

  useEffect(() => {
    list()
  }, [])

  return (
    <>
      <Grid
        templateColumns="auto 1fr"
        templateRows="auto 1fr"
        minH="100vh"
        p={0}
        gap={0}
      >
        <GridItem
          as="aside" 
          colSpan={1} 
          rowSpan={2} 
          bg="gray.100" 
          h="100vh"
          position="sticky"
          top="0"
        >
          <Sidebar />
        </GridItem>

        <GridItem as="header" colStart={2} colSpan={1} bg="gray.200" w="100%">
          <Header />
        </GridItem>

        <GridItem colStart={2} rowStart={2} w="100%" h="100%">
          <Flex>
            <div className="content">
              <div style={{marginTop: '-5%'}} className="table-header">
                <Text fontSize={"4xl"}>Eventos em destaque</Text>
                <Button leftIcon={<AddIcon />} colorScheme="blue" variant="solid" onClick={onOpenDrawer}>
                  Novo
                </Button>
              </div>
              <HighlightedEventsTable highlightedEvents={highlightedEvents} listHighlightedEvents={list} />
            </div>
          </Flex>
        </GridItem>
      </Grid>

      <Drawer
        title={'Novo destaque'}
        isOpen={isOpenDrawer}
        onClose={onCloseDrawer}
        cancelText={'Cancelar'}
        confirmText={'Salvar'}
        children={<HighlightedEventsForm onSubmit={newHighlightedEvents} onClose={onCloseDrawer} />}
      />
    </>
  );
};

export default HighlightedEvents;
