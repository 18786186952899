import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Grid,
  Text,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Input,
  GridItem,
} from "@chakra-ui/react";
import React, { MouseEvent, useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar";
import EventTable from "./components/EventTable";

import { listEvents, createEvents, listEventsByOrganizer, changePasswordFirstLogin } from "../../services/api";
import EventForm from "./components/EventForm";

import "./style.css";
import { getUser } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { IEvent } from "../../interfaces/event-interface";
import { IUser } from "../../interfaces/user-interface";
import { Header } from "../../components/Header";
import { Pagination } from "../../components/Pagination/pagination";
import { SearchInput } from "../../components/SearchInput/search-input";



const Event: React.FC = () => {

  const [user, setUser] = useState<IUser>({} as IUser);
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [searchInput, setSearchInput] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [lastPage, setLastPage] = useState<number>(1)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(50)

  const handleSearch = async () => {

    const params = `?search=${searchInput}&limit=${perPage}&page=${currentPage}`

    if (user.user_type == 'ORGANIZER') { //========== SE FOR ORGANIZADOR =========
      if(!user.id) return;
      listEventsByOrganizer(user.id, params)
        .then(
          response => {
            setEvents(response.data.items)
            setLastPage(response.data.meta.totalPages)
            setCurrentPage(response.data.meta.currentPage)
          }
        )

    } else if(user.user_type == 'ADMIN' || user.user_type == 'SUPER_ADMIN'){
      listEvents(params)
      .then(
        response => {
          setEvents(response.data.items)
          setLastPage(response.data.meta.totalPages)
          setCurrentPage(response.data.meta.currentPage)
        }
      )
      .catch(error => console.log(error))
      }
  }

  async function handleChangePasswordUser(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (password !== passwordConfirm || password === '' || passwordConfirm === '') {
      toast({
        title: "Senhas não conferem.",
        status: "error",
        duration: 4000,
        isClosable: true,
        orientation: 'vertical'
      });
      return;
    }

    if (password.length < 6) {
      toast({
        title: "Senha deve conter no minimo 6 caracteres.",
        status: "error",
        duration: 4000,
        isClosable: true,
        orientation: 'vertical'
      });
      return;
    }

    if (password === "123456") {
      toast({
        title: "Senha não pode ser igual a anterior.",
        status: "error",
        duration: 4000,
        isClosable: true,
        orientation: 'vertical'
      });
      return;
    }

    const res = await changePasswordFirstLogin({ email: user.email, password: password });
    if (res) {
      onCloseDrawerChangePassword();
      toast({
        title: "Senha alterada com sucesso.",
        status: "success",
        duration: 4000,
        isClosable: true,
        orientation: 'vertical'
      });
    } else {
      toast({
        title: "Erro ao alterar senha.",
        status: "error",
        duration: 4000,
        isClosable: true,
        orientation: 'vertical'
      });
    }
  }

  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();

  const {
    isOpen: isOpenDrawerChangePassword,
    onOpen: onOpenDrawerChangePassword,
    onClose: onCloseDrawerChangePassword,
  } = useDisclosure();

  const toast = useToast();
  const navigate = useNavigate();

  const [events, setEvents] = useState<IEvent[]>([]);

  function list() {
    const user = getUser();
    setUser(user)
    user.first_login && user.user_type === "ORGANIZER" && onOpenDrawerChangePassword();
    console.log(user)
    if (user.user_type == 'ORGANIZER') { //========== SE FOR ORGANIZADOR =========
      listEventsByOrganizer(user.id, `?search=&limit=${perPage}&page=1`)
        .then(
          response => {
            setEvents(response.data.items)
            setLastPage(response.data.meta.totalPages)
            setCurrentPage(response.data.meta.currentPage)
          }
        )
    } else if(user.user_type == 'ADMIN' || user.user_type == 'SUPER_ADMIN') { //========== SE FOR ADMIN =========
      listEvents(`?search=&limit=${perPage}&page=1`)
        .then(
          response => {
            setEvents(response.data.items)
            setLastPage(response.data.meta.totalPages)
            setCurrentPage(response.data.meta.currentPage)

          }
        )
    }
  }

  function newEvent(data: IEvent) {
    createEvents(data)
      .then(() => {
        list();
        toast({
          title: "Evento criado com sucesso.",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
      })
      .catch(() => {
        toast({
          title: "Erro ao criar Evento",
          description: "Preencha os campos corretamente.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }

  const handleNavigateNewEventPage = () => {
    navigate('/event/form');
  }

  useEffect(() => {
    handleSearch()
  }, [currentPage, perPage])

  useEffect(() => {
    if (searchInput === '') {
      list();
    }
  }, [searchInput]);

  return (
    <>
      <Grid
        templateColumns="auto 1fr"
        templateRows="auto 1fr"
        minH="100vh"
        p={0}
        gap={0}
      >
        <GridItem
          as="aside" 
          colSpan={1} 
          rowSpan={2} 
          bg="gray.100" 
          h="100vh"
          position="sticky"
          top="0"
        >
          <Sidebar />
        </GridItem>

        <GridItem as="header" colStart={2} colSpan={1} bg="gray.200" w="100%">
          <Header />
        </GridItem>

        <GridItem colStart={2} rowStart={2} w="100%" h="100%" overflow="auto">
          <Flex>
            <div className="content">
              <div style={{marginTop: '-5%'}} className="table-header">
                <Text fontSize={"4xl"}>Eventos</Text>

                <SearchInput
                  searchInput={searchInput}
                  setSearchInput={setSearchInput}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  handleSearch={handleSearch}
                  placeholder="Busque por um evento"
                />

                <Button
                  leftIcon={<AddIcon />}
                  colorScheme="blue"
                  variant="solid"
                  onClick={() => handleNavigateNewEventPage()}
                >
                  Novo
                </Button>
              </div>

              <EventTable
                events={events}
                list={list}
              />

              <Pagination
                lastPage={lastPage}
                currentPage={currentPage}
                setLastPage={setLastPage}
                setCurrentPage={setCurrentPage}
                perPage={perPage}
                setPerPage={setPerPage}
              />

            </div>
          </Flex>
        </GridItem>
      </Grid>

      <Modal isOpen={isOpenDrawer} onClose={onCloseDrawer} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Novo evento</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <EventForm onSubmit={newEvent} onClose={onCloseDrawer} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>

  );
};

export default Event;
