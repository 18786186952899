import { Flex, Grid, GridItem, Text } from "@chakra-ui/react"
import Sidebar from "../../components/Sidebar"
import { Header } from "../../components/Header"
import { toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from "react";
import { IStates } from "../../interfaces/states-interface";
import { indexStates, searchStates, toggleActiveStates } from "../../services/api";
import { DataTable } from "./data-table";
import { CustomSpinner } from "../../components/CustomSpinner/custom-spinner";
import { SearchInput } from "../../components/SearchInput/search-input";
import { error } from "console";

export const States = () => {

    const [allStates, setAllStates] = useState<IStates[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [searchInput, setSearchInput] = useState<string>('')

    async function toggleStatusState(stateId: number, isActive: boolean){
        setIsLoading(true)
        await toggleActiveStates(stateId, !isActive)
        .then(async ({data}) => {
            await getAllStates()
            toast.success(`Estado ${isActive ? 'DESATIVADO' : 'ATIVADO'} com sucesso`)
        })
        .catch(error => {
            console.log(error)
            toast.error('Ocorreu um error ao atualizar status do estado!')
        })
        setIsLoading(false)
    }

    async function getAllStates(){
        try {
            const {data} = await indexStates()
            const states = data.data
            setAllStates(states)
            
        } catch (error) {
            console.log(error)
            toast.error('Ocorreu um error ao listar todos os estados!')
        }
    }

    const handleSearch = async () => {
        setIsLoading(true)
        await searchStates(searchInput)
        .then(({data}) => {
            const states = data.data
            setAllStates(states)
        })
        .catch(error => {
            console.log(error)
            toast.error('Ocorreu um error ao listar os estados!')
        })
        setIsLoading(false)
    }

    useEffect(() => {
        if(searchInput.length === 0){
            getAllStates()
        }
    },[searchInput])

    useEffect(() => {
        getAllStates()
    },[])

    return(
        <>
            <Grid
                templateColumns="auto 1fr"
                templateRows="auto 1fr"
                minH="100vh"
                p={0}
                gap={0}
            >

                <GridItem
                    as="aside" 
                    colSpan={1} 
                    rowSpan={2} 
                    bg="gray.100" 
                    h="100vh"
                    position="sticky"
                    top="0"
                >
                    <Sidebar />
                </GridItem>

                <GridItem as="header" colStart={2} colSpan={1} bg="gray.200" w="100%">
                    <Header />
                </GridItem>

                <GridItem colStart={2} rowStart={2} w="100%" h="100%">
                    <Flex>
                        <div className="content">
                            <div style={{marginTop: '-5%'}} className="table-header">
                                <Text fontSize={"4xl"}>Estados</Text>
                            </div>

                            <div
                                style={{
                                    marginBottom: '50px'
                                }}
                            >
                                <SearchInput
                                    searchInput={searchInput}
                                    setSearchInput={setSearchInput}
                                    handleSearch={handleSearch}
                                    placeholder="Busque por um estado"
                                />
                            </div>

                            { isLoading 
                            ? <CustomSpinner/> 
                            : 
                                <DataTable
                                    allStates={allStates}
                                    toggleStatusState={toggleStatusState}
                                />
                            }
                            
                        </div>
                    </Flex>
                </GridItem>

            </Grid>
        </>
    )
}