import { Avatar, Box, HStack, IconButton, Input, Menu, MenuButton, MenuItem, MenuList, Spinner } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { getCompany, getUser, setUser } from "../../services/auth";
import { ICompanyByUser } from "../../interfaces/company-interface";
import { FaCamera, FaPencilAlt, FaSignOutAlt, FaTrashAlt } from 'react-icons/fa';
import { HamburgerIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { changeImageprofile } from "../../services/api";
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';

export const Header = () => {
    const [company, setCompany] = useState<ICompanyByUser>({} as ICompanyByUser)
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [avatarSrc, setAvatarSrc] = useState<string | ArrayBuffer | null>();

    const navigate = useNavigate();
    const user = getUser()
    const role = user.user_type;
    const inputRef = useRef<HTMLInputElement | null>(null);

     // Função para lidar com o upload da imagem do avatar
     const handleAvatarChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {

            const formData = new FormData();
            formData.append('image', file);

            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target?.result !== undefined) {
                    setAvatarSrc(e.target.result as string | ArrayBuffer); // Faz o cast para string | ArrayBuffer
                }
            };
            reader.readAsDataURL(file);

            try {
                const {data} = await changeImageprofile(user?.id, formData)

                toast.success(data.message)
                user.profileImage = data.data.urlImage
                setUser(user)
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
                
            } catch (error) {
                console.log(error)
                toast.error('Ocorreu um error ao atualizar sua foto de perfil!')
            }
        }
    };

    const handleClick = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    function getName() {
        if (user?.user_type === 'SUPER_ADMIN' || user?.user_type === 'ADMIN') {
            return user?.admin?.franchise?.company_name || user?.admin?.franchise?.name;
        }
        return user?.organizer?.company?.company_name || user?.organizer?.company?.name;
    }

    function handleLogout() {
        localStorage.clear();
        navigate('/');
        window.location.reload();
    }

    useEffect(() => {
        setIsLoading(true)

        const res = getCompany();
        setCompany(res)
        setIsLoading(false)
    }, []);

    return (
        
        <Box
            as="header"
            w="100%"
            h="60px"
            bg="gray.800"
            color="white"
            backgroundColor={"#1D1D1D"}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
        >
            
            <div
                style={{
                    height: '50px',
                    width: '50px'
                }}
            >
            </div>

            <span
                style={{
                    fontStyle: "italic",
                }}
            >
                {/* Onde é Hoje? */}
            </span>

            <Box
                style={{
                    height: '50px',
                    width: '50px',
                    paddingRight: '60px'
                }}
            >
                <Menu>
                    <MenuButton>
                    <Avatar
                        icon={!user?.profileImage ? <FaCamera /> : <></>}
                        src={user?.profileImage || ''}
                        size="md"
                        cursor="pointer"
                        mb={2}
                    />
                    </MenuButton>

                    <MenuList color='black'>
                        <MenuItem
                            icon={<FaCamera />}
                            onClick={handleClick}
                        >
                            Editar foto de perfil
                        </MenuItem>

                        <MenuItem>
                            {getName()}
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Box>

            <Input
                type="file"
                accept="image/*"
                onChange={handleAvatarChange}
                style={{ display: 'none' }}
                ref={inputRef} // Referência para o input
                id="avatar-upload"
            />
        </Box>
    );
}