import { useEffect, useState } from "react"
import { getBackAccountById, updateConfigBankAccountFranchise } from "../../../../../services/api"
import { Button, CircularProgress, Flex, FormControl, FormLabel, Grid, Input, Select, Switch, useToast } from "@chakra-ui/react"
import { useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../../../../components/Sidebar";
import { IConfigBankAccountFranchise } from "../../../../../interfaces/franchise-interface";

export const ConfigContaBancariaFranquiaFormEdit = () => {
    const [transferEnabled, setTransferEnabled] = useState<boolean>(true)
    const [transferInterval, setTransferInterval] = useState<string>('daily')
    const [transferDay, setTransferDay] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [franchiseId, setFranchiseId] = useState<string>('')

    let { id } = useParams();

    const navigate = useNavigate();
    function handleNavigate(path: string) {
        navigate(path)
    }

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        const response = await getBackAccountById({ id: Number(id) });
        const { data } = response.data;
        setTransferEnabled(data.transfer_enabled);
        setTransferInterval(data.transfer_interval);
        setTransferDay(data.transfer_day.toString());
        setFranchiseId(data.franchise_id ? data.franchise_id.toString() : '');
    }

    useEffect(() => {
        if (transferInterval === "daily") {
            setTransferDay("0")
        }
    }, [transferInterval])

    const handleSetInterval = (value: string) => {
        setTransferInterval(value)
        if (value === "weekly") {
            setTransferDay("1")
        }
    }

    const toast = useToast();

    const handleCreateBankAccount = async () => {
        setIsLoading(true)
        let data: IConfigBankAccountFranchise = {
            franchise_id: Number(franchiseId),
            transfer_day: Number(transferDay),
            transfer_interval: transferInterval,
            transfer_enabled: transferEnabled,
        } as IConfigBankAccountFranchise;

        updateConfigBankAccountFranchise({ data, id: Number(id) }).then((response) => {
            if (response.status === 201) {
                toast({
                    title: "Conta bancária atualizada com sucesso",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                })
            }
            setIsLoading(false)
            handleNavigate('/franchise/' + franchiseId);
        })
            .catch((error) => {
                setIsLoading(false)
                toast({
                    title: "Erro ao atualizar conta bancária",
                    status: "error",
                    description: error.response.data.message,
                    duration: 9000,
                    isClosable: true,
                })
            });
    }

    return (
        <>
            <Grid minH="99vh" minW="99vw" p={3} padding={0}>
                <Flex>
                    <Sidebar />
                    <div className="content">
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <h1>
                                Conta Bancaria do Recebedor
                            </h1>
                            <form
                                style={{
                                    borderTop: "1px solid #ccc",
                                }}
                                onSubmit={(e) => {
                                    e.preventDefault()
                                    handleCreateBankAccount()
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-around",
                                        width: "100%",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "start",
                                            padding: "10px",
                                            width: "100%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "50%",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "space-between",
                                                alignItems: "start",
                                                padding: "10px",
                                            }}
                                        >
                                            <FormControl>
                                                <FormLabel>Transferência habilitada</FormLabel>
                                                <Switch
                                                    isChecked={transferEnabled}
                                                    onChange={(e) => setTransferEnabled(e.target.checked)}
                                                    colorScheme="green"
                                                />
                                            </FormControl>
                                            <FormControl>
                                                <FormLabel>Intervalo de transferência</FormLabel>
                                                <Select value={transferInterval} onChange={(e) => handleSetInterval(e.target.value)}>
                                                    <option value="daily">Diário</option>
                                                    <option value="weekly">Semanal</option>
                                                    <option value="monthly">Mensal</option>
                                                </Select>
                                            </FormControl>
                                            {
                                                transferInterval === "monthly" && <FormControl>
                                                    <FormLabel>Dia de transferência</FormLabel>
                                                    <Input value={transferDay} type="number" maxLength={2} minLength={1} onChange={(e) => setTransferDay(e.target.value)} />
                                                </FormControl>
                                            }

                                            {
                                                transferInterval === "weekly" && <FormControl>	<FormLabel>Dia de transferência</FormLabel>
                                                    <Select value={transferDay} onChange={(e) => setTransferDay(e.target.value)}>
                                                        <option value="1">Segunda-feira</option>
                                                        <option value="2">Terça-feira</option>
                                                        <option value="3">Quarta-feira</option>
                                                        <option value="4">Quinta-feira</option>
                                                        <option value="5">Sexta-feira</option>
                                                        {/* <option value="6">Sábado</option>
                                                        <option value="7">Domingo</option> */}
                                                    </Select>
                                                </FormControl>
                                            }
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-around",
                                            alignItems: "center",
                                            borderTop: "1px solid #ccc",
                                            padding: "10px",
                                            marginTop: "20px",
                                            width: "100%",
                                        }}
                                    >
                                        <Button
                                            colorScheme="red"
                                            style={{
                                                width: "100%",
                                                marginRight: "10px"
                                            }}
                                            onClick={() => {
                                                if (!isLoading) {
                                                    handleNavigate('/franchise/' + franchiseId);
                                                }
                                            }}
                                            height={50}
                                        >
                                            {isLoading ? <CircularProgress
                                                isIndeterminate
                                                color="blue"
                                                size="24px"
                                            /> : 'Cancelar'}
                                        </Button>
                                        <Button
                                            colorScheme="green"
                                            width={"100%"}
                                            height={"50px"}
                                            type="submit"
                                            isDisabled={isLoading}
                                        >
                                            {isLoading ? <CircularProgress
                                                isIndeterminate
                                                color="blue"
                                                size="24px"
                                            /> : 'Salvar'}
                                        </Button>
                                    </div>
                                </div>
                            </form>

                        </div >
                    </div>
                </Flex>
            </Grid>
        </>
    )
}