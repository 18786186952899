import { Center, Spinner } from "@chakra-ui/react"

export const CustomSpinner = () => {

    return(
        <div
              style={{
                width: '100%'
              }}
            >
              <Center>
                <Spinner
                  size='xl'
                  color="#2B6CB0"
                  thickness="5px"
                />
              </Center>
            </div>
    )
}